var Handlebars = require('C:/Users/robtor/Documents/Wordpress/SSC/suborbitalexpress-2019-frontend/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " mobile-menu--with-search";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<form action=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"searchPage") || (depth0 != null ? lookupProperty(depth0,"searchPage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchPage","hash":{},"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":30}}}) : helper)))
    + "\" method=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"searchMethod") || (depth0 != null ? lookupProperty(depth0,"searchMethod") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchMethod","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":56}}}) : helper)))
    + "\" class=\"mobile-menu__search-form\">\r\n			<input type=\"search\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"searchParameter") || (depth0 != null ? lookupProperty(depth0,"searchParameter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchParameter","hash":{},"data":data,"loc":{"start":{"line":4,"column":30},"end":{"line":4,"column":49}}}) : helper)))
    + "\" autocomplete=\"off\" placeholder=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"searchPlaceholder") || (depth0 != null ? lookupProperty(depth0,"searchPlaceholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchPlaceholder","hash":{},"data":data,"loc":{"start":{"line":4,"column":83},"end":{"line":4,"column":104}}}) : helper)))
    + "\" />\r\n			<input type=\"submit\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"searchPlaceholder") || (depth0 != null ? lookupProperty(depth0,"searchPlaceholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"searchPlaceholder","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":52}}}) : helper)))
    + "\" class=\"usability--visuallyhidden\" />\r\n		</form>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav id=\"mobile-menu\" class=\"mobile-menu"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"searchField") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":40},"end":{"line":1,"column":91}}})) != null ? stack1 : "")
    + "\" role=\"navigation\" aria-hidden=\"true\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"searchField") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":7,"column":8}}})) != null ? stack1 : "")
    + "	<div role=\"menu\" id=\"mobile-menu-paneholder\"></div>\r\n</nav>\r\n<div id=\"mobile-menu-page-cover\" class=\"mobile-menu-page-cover\"></div>\r\n";
},"useData":true});