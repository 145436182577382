import ToggleLink from './components/toggleLink/toggleLink';
import Megadrop from './components/megadrop/megadrop';
import MobileMenu from './components/mobileMenu/mobileMenu';
import TabUsability from './components/tabUsability/tabUsability';
import Counter from './components/counter/counter';
import ObjectFitImages from 'object-fit-images';
import EpiserverForm from './components/episerverForm/episerverForm';
import { wrap } from './components/utilities/utilities';

/**
 * App
 * This starting point for this application.
 * @version 3.0.0
 * @exports App
 */
export default class App {
	constructor() {
		// Init menu
		new Megadrop();

		// Init mobile menu
		new MobileMenu(document.querySelector('[data-type="mobileMenu"]'), {
			ajaxPath: mainConfig.menuAjaxPath,
			ajaxActivePane: mainConfig.menuAjaxActivePane,
			searchField: false,
			searchMethod: mainConfig.searchMethod,
			searchPage: mainConfig.searchPage,
			searchParameter: mainConfig.searchParameter,
			searchPlaceholder: mainConfig.searchPlaceholder,
		});

		// Init toggle link
		new ToggleLink();

		// Init tabUsability
		new TabUsability();

		// Init table wrap for mobile scrolling of tables in rte.
		this._initRteTableWrap();

		// Init ajaxForm
		this._initAjaxForm();

		// Init episerverForms
		this._initEpiserverForm();

		this._initAnchorLinks();

		ObjectFitImages();

		if (document.querySelector('[data-type="counter"]')) {
			new Counter(document.querySelector('[data-type="counter"]'));
		}
	}

	/**
	 * Function that initiates the ajax-form functionality.
	 * @private
	 */
	_initAjaxForm() {
		let els = document.querySelectorAll('[data-type="ajaxForm"]');

		if (els.length > 0) {
			import(/* webpackChunkName: "ajaxForm" */ './components/ajaxForm/ajaxForm').then(
				AjaxForm => {
					for (let i = 0; i < els.length; i++) {
						let el = els[i];

						if (el.getAttribute('data-ajaxform-initiated') === null) {
							new AjaxForm.default(el);
							el.setAttribute('data-ajaxform-initiated', true);
						}
					}
				}
			);
		}
	}

	/**
	 * Function that initiates the episerverform functionality.
	 * @private
	 */
	_initEpiserverForm() {
		let els = document.querySelectorAll('[data-type="episerverForm"]');

		if (els.length > 0) {
			for (let i = 0; i < els.length; i++) {
				let el = els[i];

				if (el.getAttribute('data-episerverform-initiated') === null) {
					new EpiserverForm(el);
					el.setAttribute('data-episerverform-initiated', true);
				}
			}
		}
	}

	/**
	 * Function that wraps all tables in rte with a div to enable the scroll-feature in mobile.
	 * @private
	 */
	_initRteTableWrap() {
		let els = document.querySelectorAll('.rte table');

		els.forEach(el => {
			let wrapper = document.createElement('div');
			wrapper.classList.add('table-scrollable');
			wrap(el, wrapper);
		});
	}

	/**
	 * Function that initiates scrolling for anchor links.
	 * @private
	 */
	_initAnchorLinks() {
		// Handle internal anchor links.
		const links = document.querySelectorAll(
			'a[href^="#"]:not(.nav-primary__menu__item__link):not(.accordion__link):not([href="#mobile-menu"])'
		);
		links.forEach(el => {
			el.addEventListener('click', event => {
				const linkUrl = event.target.href;

				if (linkUrl) {
					const anchorPosition = linkUrl.indexOf('#');

					if (anchorPosition !== -1) {
						event.preventDefault();

						const hash = linkUrl.substring(anchorPosition);
						const target = document.querySelector(hash);

						target.scrollIntoView({
							behavior: 'smooth',
							block: 'start',
						});

						history.pushState(null, null, hash);
					}
				}
			});
		});
	}
}
