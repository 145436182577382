/* eslint one-var: "off" */
import axios from 'axios';
import { remove } from '../utilities/utilities';

/**
 * EpiserverForm
 * A plugin that handles Episerver forms in an accessible way.
 * @version 1.0.0
 * @exports episerverForm
 */
export default class EpiserverForm {
	/**
	 * Constructor
	 * @param {Object} el - A DOM node.
	 * @public
	 */
	constructor(el) {
		/** Placeholder for form node. */
		this.formNode = el;

		/** Boolean to indicate if there are any ajax-request in progress. */
		this.requestInProgress = false;

		this._init();
	}

	/**
	 * The initialization function for this module.
	 * @public
	 */
	_init() {
		this.formMessageNode = this.formNode.querySelector('.form__message');
		this.fieldHolderNode = this.formNode.querySelector('.form__fields-holder');

		this._initialFocus();
		this._initEvents();
	}

	/**
	 * Function that sets focus to the form__status container on load.
	 * @private
	 */
	_initialFocus() {
		const statusNode = this.formNode.querySelector('.form__status');

		if (statusNode) {
			statusNode.focus();
		}
	}

	/**
	 * Function that submits the form through ajax.
	 * @private
	 */
	_submit(stepData, stepId) {
		const method = this.formNode.getAttribute('method')
			? this.formNode.getAttribute('method')
			: 'POST';
		const url = this.formNode.getAttribute('action');
		const formData = stepData || new FormData(this.formNode);
		let data = {};

		if (this.requestInProgress === false) {
			this.requestInProgress = true;

			axios({
				url: url,
				method: method,
				data: formData,
				headers: {
					'X-Requested-With': 'XMLHttpRequest',
				},
			})
				.then(response => {
					if (response.status === 200) {
						data = response.data;

						this._cleanupValidationErrors();
						this._hideLoadingIndicator();

						if (!data.IsSuccess) {
							this._handleFormError(data);
						}

						// Update hidden fields if needed.
						if (stepId && data.Data && data.Data.CurrentStepIndex) {
							for (let key in data.Data) {
								if (data.Data.hasOwnProperty(key)) {
									const field = this.formNode.querySelector(
										'[name="__Form' + key + '"]'
									);
									if (field) {
										field.value = data.Data[key];
									}
								}
							}
						}
						if (stepId && data.IsSuccess) {
							this._showStep(stepId);
						} else if (
							this.formMessageNode &&
							data.Message &&
							data.Message !== ''
						) {
							this._displayMessage(data);
						}
					} else {
						this._hideLoadingIndicator();
						alert(
							this.formNode.getAttribute('data-server-error-message') ||
								'Ett fel inträffade, vänligen försök igen senare.'
						);
					}
					this.requestInProgress = false;
				})
				.catch(() => {});
		}
	}

	/**
	 * Removes validation invalid classes and validation error labels.
	 * @private
	 */
	_cleanupValidationErrors() {
		const invalidFields = this.formNode.querySelectorAll(
			'.form__field--invalid'
		);
		const invalidLabels = this.formNode.querySelectorAll(
			'.form__label--invalid'
		);

		invalidFields.forEach(field => {
			field.classList.remove('form__field--invalid');
		});

		invalidLabels.forEach(label => {
			remove(label);
		});
	}

	/**
	 * Handles serverside validation errors.
	 * @param {Object} data - Form response data.
	 * @private
	 */
	_handleFormError(data) {
		if (data.AdditionalParams && data.AdditionalParams.__FormField) {
			data.AdditionalParams.__FormField.forEach((field, index) => {
				const fieldNode = document.getElementById(field.InvalidElement);
				let fieldType;

				if (fieldNode) {
					fieldType = fieldNode.getAttribute('type');
					fieldNode.classList.add('form__field--invalid');

					if (field.ValidationMessage && field.ValidationMessage !== '') {
						const validationMessageNode =
							fieldNode.tagName === 'FIELDSET'
								? document.createElement('p')
								: document.createElement('label');
						const validationMessage = document.createTextNode(
							field.ValidationMessage
						);

						validationMessageNode.appendChild(validationMessage);
						validationMessageNode.classList.add('form__label--invalid');

						if (fieldNode.tagName === 'FIELDSET') {
							validationMessageNode.setAttribute('tabindex', -1);

							const fieldNodeLegend = fieldNode.querySelector('legend');
							if (fieldNodeLegend) {
								fieldNodeLegend.parentNode.insertBefore(
									validationMessageNode,
									fieldNodeLegend.nextSibling
								);
							}

							if (index === 0) {
								validationMessageNode.focus();
							}
						} else {
							validationMessageNode.setAttribute('for', field.InvalidElement);

							if (fieldType === 'checkbox' || fieldType === 'radio') {
								const fieldNodeLabel = this.formNode.querySelector(
									'[for="' + field.InvalidElement + '"]'
								);
								if (fieldNodeLabel) {
									fieldNodeLabel.parentNode.insertBefore(
										validationMessageNode,
										fieldNodeLabel.nextSibling
									);
								}
							} else {
								fieldNode.parentNode.insertBefore(
									validationMessageNode,
									fieldNode.nextSibling
								);
							}
						}
					}

					if (index === 0) {
						if (fieldNode.tagName !== 'FIELDSET') {
							fieldNode.focus();
						}

						// Set cursor at the end of the fields value.
						if (fieldNode.selectionStart || fieldNode.selectionStart == '0') {
							const elementValueLength = fieldNode.value.length;
							fieldNode.selectionStart = elementValueLength;
							fieldNode.selectionEnd = elementValueLength;
							fieldNode.focus();
						}
					}
				}
			});
		}
	}

	/**
	 * Displays a message above the form.
	 * @param {Object} data - Form response data.
	 * @private
	 */
	_displayMessage(data) {
		const responseMessageNode = document.createElement('div');

		responseMessageNode.setAttribute('tabindex', '-1');
		responseMessageNode.classList.add('form__status');
		responseMessageNode.classList.add('form__rte');

		if (data.IsSuccess) {
			responseMessageNode.classList.add('form__status--success');
		} else {
			responseMessageNode.classList.add('form__status--error');
		}

		responseMessageNode.innerHTML = data.Message;

		// Remove all content in formMessageNode
		while (this.formMessageNode.firstChild) {
			this.formMessageNode.removeChild(this.formMessageNode.firstChild);
		}

		this.formMessageNode.appendChild(responseMessageNode);
		responseMessageNode.focus();
		this.fieldHolderNode.classList.add('form__fields-holder--hidden');
	}

	/**
	 * Submits a step in a multi step form.
	 * @param {String} stepId - The id of the step section node.
	 * @private
	 */
	_submitStep(stepId) {
		const currentStep = this.formNode.querySelector(
			'.form__section[aria-hidden="false"]'
		);
		const hiddenFieldHolder = this.formNode.querySelector(
			'.form__hidden-fields'
		);
		const formData = new FormData();
		let currentStepFields = [];
		let hiddenFields = [];

		if (hiddenFieldHolder) {
			hiddenFields = hiddenFieldHolder.querySelectorAll('input, select');

			hiddenFields.forEach(field => {
				formData.append(field.getAttribute('name'), field.value);
			});
		}

		if (currentStep) {
			currentStepFields = currentStep.querySelectorAll(
				'input, select, textarea'
			);

			currentStepFields.forEach(field => {
				const type = field.getAttribute('type');
				// Selects
				if (field.options) {
					for (let i = 0; i < field.options.length; i++) {
						if (field.options[i].selected) {
							formData.append(
								field.getAttribute('name'),
								field.options[i].value
							);
						}
					}
				}
				// Checkbox and radio
				else if (type === 'checkbox' || type === 'radio') {
					if (field.checked) {
						formData.append(field.getAttribute('name'), field.value);
					}
				}
				// Files
				else if (type === 'file') {
					for (let j = 0; j < field.files.length; j++) {
						formData.append(field.getAttribute('name'), field.files[j]);
					}
					// All other fields
				} else {
					formData.append(field.getAttribute('name'), field.value);
				}
			});

			this._submit(formData, stepId);
		}
	}

	/**
	 * Validates a step in a multi step form.
	 * @private
	 */
	_validateStep() {
		const currentStep = this.formNode.querySelector(
			'.form__section[aria-hidden="false"]'
		);
		let currentStepFields = [];
		let stepIsValid = true;

		// Test browser support
		if (typeof document.createElement('input').checkValidity !== 'function') {
			return stepIsValid;
		}

		if (currentStep) {
			currentStepFields = currentStep.querySelectorAll('input, select');
		}

		currentStepFields.forEach(field => {
			if (!field.checkValidity()) {
				if (stepIsValid) {
					field.reportValidity();
				}
				stepIsValid = false;
			}
		});

		return stepIsValid;
	}

	/**
	 * Shows a step in a multi step form.
	 * @param {String} stepId - The id of the step section node.
	 * @private
	 */
	_showStep(stepId) {
		const stepButtons = this.formNode.querySelectorAll('[aria-controls]');
		const steps = this.formNode.querySelectorAll('.form__section');
		const nextStep = document.getElementById(stepId);
		const nextStepHeadline = nextStep.querySelector('.form__section__headline');
		const nextStepButton = this.formNode.querySelector(
			'[aria-controls=' + stepId + ']'
		);

		stepButtons.forEach(btn => {
			btn.setAttribute('aria-expanded', false);
		});

		steps.forEach(step => {
			step.setAttribute('aria-hidden', true);
			step.classList.add('form__section--hidden');
		});

		nextStep.setAttribute('aria-hidden', false);
		nextStep.classList.remove('form__section--hidden');
		nextStepButton.setAttribute('aria-expanded', true);

		if (nextStepHeadline) {
			nextStepHeadline.focus();
		}
	}

	/**
	 * Displays a spinner and a message in button.
	 * @param {Object} btn - A button node.
	 * @private
	 */
	_displayLoadingIndicator(btn) {
		const loadingMessage =
			btn.getAttribute('data-loading-message') || 'Laddar...';
		btn.setAttribute('data-innerhtml', btn.innerHTML);
		btn.classList.add('form__button--loading');
		btn.innerHTML =
			'<span class="form__loader" aria-hidden="true"></span> ' + loadingMessage;
	}

	/**
	 * Hides the spinner and message in buttons and restores their innerHTML.
	 * @private
	 */
	_hideLoadingIndicator() {
		const loadingButtons = this.formNode.querySelectorAll(
			'.form__button--loading'
		);

		loadingButtons.forEach(btn => {
			const oldInnerHtml = btn.getAttribute('data-innerhtml');
			btn.innerHTML = oldInnerHtml;
		});
	}

	/**
	 * Returns the next button for the current step.
	 * @private
	 */
	_getCurrentNextButton() {
		const nextButtons = this.formNode.querySelectorAll('.form__button--next');
		let currentNextButton = null;

		nextButtons.forEach(btn => {
			if (btn.offsetParent !== null) {
				currentNextButton = btn;
			}
		});

		return currentNextButton;
	}

	/**
	 * Initiates events.
	 * @private
	 */
	_initEvents() {
		const multistepButtons = this.formNode.querySelectorAll('[aria-controls]');

		this.formNode.addEventListener('submit', event => {
			// If the user submits with Enter press we need to handle multistep forms.
			const currentNextButton = this._getCurrentNextButton();
			if (currentNextButton) {
				this._submitStep(currentNextButton.getAttribute('aria-controls'));
				event.preventDefault();
				return false;
			}

			if (typeof FormData !== 'undefined') {
				if (event.preventDefault) {
					event.preventDefault();
				} else {
					event.returnValue = false;
				}

				const submitBtn = this.formNode.querySelector('[type="submit"]');
				if (submitBtn) {
					this._displayLoadingIndicator(submitBtn);
				}
				this._submit();
			}
		});

		multistepButtons.forEach(btn => {
			btn.addEventListener('click', () => {
				if (btn.getAttribute('data-epiform-previous')) {
					const currentStepIndexField = this.formNode.querySelector(
						'[name="__FormCurrentStepIndex"]'
					);
					if (currentStepIndexField) {
						currentStepIndexField.value =
							parseInt(currentStepIndexField.value, 10) - 1;
					}
					this._showStep(btn.getAttribute('aria-controls'));
					return false;
				}
				if (typeof FormData !== 'undefined') {
					if (
						btn.classList.contains('form__button--prev') ||
						this._validateStep()
					) {
						this._displayLoadingIndicator(btn);
						this._submitStep(btn.getAttribute('aria-controls'));
					}
				} else {
					this.formNode.submit();
				}
			});
		});
	}
}
