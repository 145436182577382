var Handlebars = require('C:/Users/robtor/Documents/Wordpress/SSC/suborbitalexpress-2019-frontend/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"classlist") || (depth0 != null ? lookupProperty(depth0,"classlist") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"classlist","hash":{},"data":data,"loc":{"start":{"line":1,"column":118},"end":{"line":1,"column":131}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<li class=\"mobile-menu__header\">\r\n					<a href=\"#mobile-menu-pane-"
    + alias4(((helper = (helper = lookupProperty(helpers,"parent") || (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parent","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":42}}}) : helper)))
    + "\" data-target=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"parent") || (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parent","hash":{},"data":data,"loc":{"start":{"line":6,"column":57},"end":{"line":6,"column":67}}}) : helper)))
    + "\" aria-controls=\"mobile-menu-pane-"
    + alias4(((helper = (helper = lookupProperty(helpers,"parent") || (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parent","hash":{},"data":data,"loc":{"start":{"line":6,"column":101},"end":{"line":6,"column":111}}}) : helper)))
    + "\" aria-expanded=\"false\" class=\"mobile-menu__header__back-link mobile-menu-nav\">&lt;</a>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentUrl") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":7,"column":5},"end":{"line":11,"column":12}}})) != null ? stack1 : "")
    + "				</li>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"parentUrl") || (depth0 != null ? lookupProperty(depth0,"parentUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parentUrl","hash":{},"data":data,"loc":{"start":{"line":8,"column":15},"end":{"line":8,"column":28}}}) : helper)))
    + "\" class=\"mobile-menu__header__title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"parentName") || (depth0 != null ? lookupProperty(depth0,"parentName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parentName","hash":{},"data":data,"loc":{"start":{"line":8,"column":65},"end":{"line":8,"column":79}}}) : helper)))
    + "</a>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<a href=\"#mobile-menu-pane-"
    + alias4(((helper = (helper = lookupProperty(helpers,"parent") || (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parent","hash":{},"data":data,"loc":{"start":{"line":10,"column":33},"end":{"line":10,"column":43}}}) : helper)))
    + "\" data-target=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"parent") || (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parent","hash":{},"data":data,"loc":{"start":{"line":10,"column":58},"end":{"line":10,"column":68}}}) : helper)))
    + "\" aria-controls=\"mobile-menu-pane-"
    + alias4(((helper = (helper = lookupProperty(helpers,"parent") || (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parent","hash":{},"data":data,"loc":{"start":{"line":10,"column":102},"end":{"line":10,"column":112}}}) : helper)))
    + "\" aria-expanded=\"false\" class=\"mobile-menu__header__title mobile-menu-nav\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"parentName") || (depth0 != null ? lookupProperty(depth0,"parentName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parentName","hash":{},"data":data,"loc":{"start":{"line":10,"column":187},"end":{"line":10,"column":201}}}) : helper)))
    + "</a>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<li>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"link") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":5},"end":{"line":18,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":19,"column":5},"end":{"line":23,"column":12}}})) != null ? stack1 : "")
    + "				</li>\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? lookupProperty(stack1,"classlist") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":28},"end":{"line":17,"column":84}}})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "</a>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"link") : depth0)) != null ? lookupProperty(stack1,"classlist") : stack1), depth0))
    + "\"";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<a href=\"#mobile-menu-pane-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":33},"end":{"line":20,"column":39}}}) : helper)))
    + "\" data-target=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":54},"end":{"line":20,"column":60}}}) : helper)))
    + "\" aria-controls=\"mobile-menu-pane-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":94},"end":{"line":20,"column":100}}}) : helper)))
    + "\" aria-expanded=\"false\" class=\"mobile-menu-expand mobile-menu-nav\">+</a>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasChildren") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":5},"end":{"line":23,"column":5}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<a href=\"#mobile-menu-pane-"
    + alias4(((helper = (helper = lookupProperty(helpers,"hasChildren") || (depth0 != null ? lookupProperty(depth0,"hasChildren") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hasChildren","hash":{},"data":data,"loc":{"start":{"line":22,"column":33},"end":{"line":22,"column":48}}}) : helper)))
    + "\" data-target=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"hasChildren") || (depth0 != null ? lookupProperty(depth0,"hasChildren") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hasChildren","hash":{},"data":data,"loc":{"start":{"line":22,"column":63},"end":{"line":22,"column":78}}}) : helper)))
    + "\" aria-controls=\"mobile-menu-pane-"
    + alias4(((helper = (helper = lookupProperty(helpers,"hasChildren") || (depth0 != null ? lookupProperty(depth0,"hasChildren") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hasChildren","hash":{},"data":data,"loc":{"start":{"line":22,"column":112},"end":{"line":22,"column":127}}}) : helper)))
    + "\" aria-expanded=\"false\" class=\"mobile-menu-expand mobile-menu-nav\">+</a>\r\n					";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"mobile-menu-pane-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":32}}}) : helper)))
    + "\" data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":49}}}) : helper)))
    + "\" data-parent=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"parent") || (depth0 != null ? lookupProperty(depth0,"parent") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"parent","hash":{},"data":data,"loc":{"start":{"line":1,"column":64},"end":{"line":1,"column":74}}}) : helper)))
    + "\" class=\"mobile-menu__pane"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"classlist") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":100},"end":{"line":1,"column":138}}})) != null ? stack1 : "")
    + "\">\r\n	<div class=\"scroll\">\r\n		<ul>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"parent") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":3},"end":{"line":13,"column":10}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":3},"end":{"line":25,"column":12}}})) != null ? stack1 : "")
    + "		</ul>\r\n	</div>\r\n</div>\r\n";
},"useData":true});