import { rerenderElement, triggerEvent } from '../utilities/utilities';

/**
 * Toggle link
 * A plugin for making links toggle other content (with accessibility features).
 * @version 3.0.0
 * @exports ToggleLink
 */
export default class ToggleLink {
	/**
	 * Constructor
	 * @public
	 */
	constructor() {
		this.openDropdown = null;

		this._initToggleLink();
		this._initToggleSelect();
		this._initAnchorSupport();

		if (document.querySelector('.toggle-dropdown') !== null) {
			this._attachOutsideClickEvent();
		}
	}

	/**
	 * Add toggle feature to the links.
	 * @private
	 */
	_initToggleLink() {
		let els = document.querySelectorAll('[data-type="toggle"]');

		els.forEach(el => {
			let target = document.getElementById(el.getAttribute('aria-controls'));

			if (
				el.tagName.toLowerCase() === 'a' &&
				el.getAttribute('role') === null
			) {
				el.setAttribute('role', 'button');
			}

			el.addEventListener('click', event => {
				let link =
					event.target.tagName === 'A' ? event.target : event.target.parentNode;

				event.preventDefault();
				if (event.stopPropagation !== undefined) {
					event.stopPropagation();
				}

				let currentState = target.getAttribute('aria-hidden') === 'true',
					group = target.getAttribute('data-togglegroup'),
					groupNoClose =
						(target.getAttribute('data-togglegoup-noclose') &&
							target.getAttribute('data-togglegoup-noclose') === 'true') ||
						false;

				// Close other toggles that are opened in this group.
				if (group !== undefined) {
					let groups = document.querySelectorAll(
						'[data-togglegroup="' + group + '"][aria-hidden="false"]'
					);
					groups.forEach(groupEl => {
						let groupElId = groupEl.getAttribute('id');
						if (
							document.querySelector('[href="#' + groupElId + '"]') !== link
						) {
							this._closeContent(
								document.querySelector('[href="#' + groupElId + '"]')
							);
						}
					});

					// If this is the only open content in the group and we don't allow all contents to be closed, stop here.
					if (groupNoClose && !currentState) {
						return false;
					}
				}

				currentState === true
					? this._openContent(link)
					: this._closeContent(link);
			});

			this._initValidation(el);
		});
	}

	/**
	 * Checks if element has required input fields and adds oninvalid event.
	 * @param {Object} el - The toggle-link a-tag element.
	 * @private
	 */
	_initValidation(el) {
		let requiredFields = document
			.getElementById(el.getAttribute('aria-controls'))
			.querySelectorAll('input[required]');

		if (requiredFields) {
			requiredFields.forEach(field => {
				field.addEventListener('invalid', () => {
					if (el.getAttribute('aria-expanded') === 'false') {
						this._openContent(el);
					}
				});
			});
		}
	}

	/**
	 * Add toggle feature to the selects.
	 * @private
	 */
	_initToggleSelect() {
		let els = document.querySelectorAll('[data-type="toggle-select"]');

		els.forEach(el => {
			el.addEventListener('change', () => {
				let targetLink = document.querySelector(
					'[aria-controls="' + el.value + '"]'
				);
				if (targetLink !== null) {
					triggerEvent(targetLink, 'click');
				}
			});
		});
	}

	/**
	 * Opens the content.
	 * @param {Object} link - The link to be opened as a DOM Node.
	 * @private
	 */
	_openContent(link) {
		let target = document.getElementById(link.getAttribute('aria-controls')),
			currentText = link.innerHTML,
			nextText = link.getAttribute('data-toggletext') || currentText,
			dropdown = link.parentNode.classList.contains('toggle-dropdown')
				? link.parentNode
				: null,
			activeClass = link.getAttribute('data-active-class') || 'is-active',
			inactiveClass = link.getAttribute('data-inactive-class') || false;

		target.setAttribute('aria-hidden', false);
		link.setAttribute('aria-expanded', true);
		link.setAttribute('data-toggletext', currentText);
		link.innerHTML = nextText;
		rerenderElement('lt-ie9');

		link.classList.add(activeClass);
		if (inactiveClass) {
			link.classList.remove(inactiveClass);
		}

		if (dropdown) {
			this.openDropdown = link;
		}

		if (target.querySelector('input[type="text"]') !== null) {
			target.querySelector('input[type="text"]').focus();
		} else if (target.querySelector('input[type="search"]') !== null) {
			target.querySelector('input[type="search"]').focus();
		} else {
			if (target.getAttribute('tabindex') === null) {
				target.setAttribute('tabindex', '-1');
			}
			target.focus();
		}
	}

	/**
	 * Close the content.
	 * @param {Object} link - The link to be closed as a DOM Node.
	 * @private
	 */
	_closeContent(link) {
		let target = document.getElementById(link.getAttribute('aria-controls')),
			currentText = link.innerHTML,
			nextText = link.getAttribute('data-toggletext') || currentText,
			activeClass = link.getAttribute('data-active-class') || 'is-active',
			inactiveClass = link.getAttribute('data-inactive-class') || false;

		target.setAttribute('aria-hidden', true);
		link.setAttribute('aria-expanded', false);
		link.setAttribute('data-toggletext', currentText);
		link.innerHTML = nextText;
		rerenderElement('lt-ie9');

		link.classList.remove(activeClass);
		if (inactiveClass) {
			link.classList.add(inactiveClass);
		}

		this.openDropdown = null;
	}

	/**
	 * If we got an anchor in the url, open the matching togglelink (and it's parents if they are nested).
	 * @private
	 */
	_initAnchorSupport() {
		let hash = window.location.hash,
			link = null,
			target = null,
			linkParent = null,
			linkParents = [];

		if (hash === '' || document.getElementById(hash) === null) {
			return false;
		}

		hash = hash.replace('#', '');
		target = document.getElementById(hash);
		link = document.querySelector('[aria-controls="' + hash + '"]');
		linkParent = target.parentNode;

		if (target === null || link === null) {
			return false;
		}

		while (linkParent !== null) {
			if (linkParent.classList.contains('expandable') !== false) {
				linkParents.push(linkParent);
			}
			linkParent = linkParent.parentNode;
		}

		for (let i = 0; i < linkParents.length; i++) {
			let l = document.querySelector(
				'[aria-controls="' + linkParents[i].getAttribute('id') + '"]'
			);

			this._openContent(l);
		}

		this._openContent(link);
	}

	/**
	 * Attach an click-event to the window object that hides open dropdown on click outside them.
	 * @private
	 */
	_attachOutsideClickEvent() {
		window.addEventListener('click', event => {
			if (this.openDropdown !== null) {
				let currentTag = event.target;

				while (
					currentTag !== null &&
					(!currentTag.classList ||
						!currentTag.classList.contains('toggle-dropdown'))
				) {
					currentTag = currentTag.parentNode;
				}

				if (currentTag === null) {
					triggerEvent(this.openDropdown, 'click');
				}
			}
		});
	}
}
