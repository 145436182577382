/**
 * Counter
 * Counter that counts down in days, hours, minutes and seconds.
 * @version 3.0.0
 * @exports Counter
 */
export default class Counter {
	/**
	 * Constructor
	 * @public
	 */
	constructor(el) {
		this.el = el;
		this.endDate = this._getEndDate(el.getAttribute('data-enddate'));
		this._initCounter();
	}

	/**
	 * Get the end date as a timestamp.
	 * @private
	 */
	_getEndDate(dateStr) {
		const a = dateStr.split(' ');
		const d = a[0].split('-');
		const t = a[1].split(':');
		return new Date(d[0], d[1] - 1, d[2], t[0], t[1], t[2]).getTime();
	}

	/**
	 * Initiates counter.
	 * @private
	 */
	_initCounter() {
		setInterval(() => {
			const now = new Date().getTime();
			const timeLeft = this.endDate - now;

			if (timeLeft >= 0) {
				const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
				const hours = Math.floor(
					(timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				);
				const mins = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
				const secs = Math.floor((timeLeft % (1000 * 60)) / 1000);

				this._writeNumbers(days, hours, mins, secs);
			}
		}, 1000);
	}

	/**
	 * Writes out days, hours, minutes and seconds left until end date in counter.
	 * @param {Number} days
	 * @param {Number} hours
	 * @param {Number} mins
	 * @param {Number} secs
	 * @private
	 */
	_writeNumbers(days, hours, mins, secs) {
		document.getElementById('counter-days').textContent = days;
		document.getElementById('counter-hours').textContent = hours;
		document.getElementById('counter-minutes').textContent = mins;
		document.getElementById('counter-seconds').textContent = secs;
	}
}
